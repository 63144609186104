$(document).ready(function(){
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		$('h2').wrap('<div class="h2-wrap"></div>');
    $('.careers-page-lists').on('click','.btn-apply', function(e) {
      e.preventDefault();
      $('#career-modal').modal('show');
    })
    
		// CUSTOM FUNCTIONS
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		search();
		display_slick();
    objLightcase();
    getfile();

    $( "#page-slider .item" )
      .mouseenter(function() {
        $(this).addClass('hovered');
        $('#page-slider .item').addClass('not-hovered');
        $(this).removeClass('not-hovered');
      })
      .mouseleave(function() {
        $(this).removeClass('hovered');
        $('#page-slider .item').removeClass('not-hovered');
    });

    if( $('.equal-height').is(':visible') ) {
		  $('.equal-height').matchHeight();
    }

    if( $('.custom-scroll').is(':visible') ) {
      $(".custom-scroll").mCustomScrollbar({
        axis:"y",
        updateOnContentResize: true
      });
    }

    $('.tab-heading').on('click', 'a', function(e) {
      e.preventDefault();
      var $keyID = $(this).data('keyid');
      $('.tab-heading a').removeClass('active');
      if( !$(this).hasClass('active') ) {
        $(this).addClass('active');
        $('.tab-content .content').removeClass('active');
        $('#tb-'+$keyID).addClass('active');
      }
    });

		$('.loader-overlay').fadeOut(200);

		$(document).on('click', '.desktop-menu-btn, .side-btn-close', function(){
			$('.side-menu, .side-menu-overlay').toggleClass('active');
		});

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	sidemenu();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	sidemenu();
}
else{
	sidemenu();
}

function search(){
	$(document).on('click', '.search-menu-btn, .close-search', function(){
		$('.search-form').toggleClass('active');
	});
}
function getfile(){
    $(document).on('click', '.attachment__button', function(){
        $('.your-cv input').click();
    });
    $('.your-cv input').on('change', function(){
        var file = $(this)[0].files[0];
        $('.attachment__name').val(file.name);
    });
    $('.Submit').on('click', function(){
      var cv = $('.your-cv input').val();
      if(cv){
        $('.attachment__name').removeClass('wpcf7-not-valid');
      } 
      else{
        $('.attachment__name').addClass('wpcf7-not-valid');
      }   
    });
}
function sidemenu(){
	var $pageHeight = $(window).height(),
			$sideHeader = $('.side-menu .side-header').outerHeight();

	$('.side-menu .side-body').css({
		'max-height' : $pageHeight - $sideHeader
	});

	$('.side-menu .side-body .nav .menu li').each(function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="dropdown-btn"><span class="fas fa-angle-down"></span></span>');
		}
		$(this).find('.dropdown-btn').on('click', function(e){
			e.preventDefault();
			$(this).find('.sub-menu').addClass('show');
		});
	});

	$(document).on('click', '.dropdown-btn', function(e){
		e.preventDefault();
		$(this).find('span').toggleClass('fa-angle-down fa-angle-up');
		$(this).parent('a').parent('li').find('.sub-menu').first().toggleClass('show');
	});

}

function display_slick() {
  $('#banner-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    fade: true,
  });
  $('#page-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            }
        }
    ]
  });
  $('#clients-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            }
        }
    ]
  });
  $('#services-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
            }
        },
        {
            breakpoint: 768,
            settings: {
             	slidesToShow: 2,
    					slidesToScroll: 2,
    					arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
    					slidesToScroll: 1,
    					arrows: false,
            }
        }
    ]
  });
  $('#news-related-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            }
        }
    ]
  });
}